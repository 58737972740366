<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-row justify="center">
          <v-col cols="auto">
            <mex-p content="Set cycle type thresholds" fontSize="h5" fontWeight="bold-italic" />
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col cols="12">
            <mex-sperm-spinner v-if="cycleGroupTemplatesLoading" :spinnerText="cycleGroupTemplatesLoadingText" />
            <transition-group v-else name="cycleGroupTemplate" tag="div">
              <v-layout row wrap :key="'cycleGroupTemplateLayout'">
                <v-col v-for="(cycleGroupTemplate, cycleGroupTemplateIdx) in cycleGroupTemplates" :key="cycleGroupTemplate.cycleGroupTemplateID" v-bind:class="'col-12 col-lg-6 col-xl-4'">
                  <mex-sheet rounded>
                    <v-row>
                      <v-col cols="8">
                        <!-- Cycle Group Template ------------------------------------------------------------------------------>
                        <v-text-field
                          outlined
                          dense
                          v-model="cycleGroupTemplate.name"
                          :rules="[cycleGroupTemplateRules.unique]"
                          label="Title of Template"
                          :readonly="!cycleGroupTemplate.editMode"
                          color="primaryAccent"
                        ></v-text-field>
                      </v-col>
                      <v-spacer></v-spacer>
                      <mex-btn v-if="cycleGroupTemplate.editMode" tooltip="Delete Template" icon-only icon="mdi-close" @click="(showDeletionWarnDialog = true) && (deleteCandidateIndex = cycleGroupTemplateIdx)"></mex-btn>
                      <v-col cols="12">
                        <v-divider />
                      </v-col>
                      <v-col cols="8">
                        <v-row
                          v-for="(cycleTypeSelector, cycleTypeSelectorIdx) in cycleGroupTemplate.cycleTypeGroups"
                          :key="`cycleGroupTemplate-${cycleGroupTemplateIdx}-cycleTypeSelector-${cycleTypeSelectorIdx}`"
                        >
                          <v-col align-self="center" cols="10">
                            <v-autocomplete
                              v-model="cycleTypeSelector.selectedCycleType"
                              :items="cycleGroupTemplate.cycleTypes"
                              :readonly="!cycleGroupTemplate.editMode"
                              color="primaryAccent"
                              dense
                              item-disabled="disabled"
                              hide-details
                              @change="() => adjustCycleTypes(cycleGroupTemplate, cycleTypeSelector.selectedCycleType)"
                              label="Cycle Types"
                              outlined
                            />
                          </v-col>
                          <v-col v-if="cycleGroupTemplate.cycleTypeGroups[0].selectedCycleType !== null" align-self="center" cols="1">
                            <mex-btn
                              :disabled="!cycleGroupTemplate.editMode"
                              icon="mdi-close"
                              icon-only
                              x-small
                              @click="removeCycleTypeSelector(cycleGroupTemplateIdx, cycleTypeSelectorIdx)"
                            ></mex-btn>
                          </v-col>
                        </v-row>
                        <v-row>
                          <!-- Add Type Selector ---------------------------------->
                          <v-col align-self="center" cols="1">
                            <v-row>
                              <v-col cols="auto">
                                <mex-btn :disabled="!cycleGroupTemplate.editMode || (cycleGroupTemplate.cycleTypeGroups.findIndex(ct => ct.selectedCycleType === null) !== -1)" icon="mdi-plus" iconOnly @click="addCycleTypeSelector(cycleGroupTemplate)" />
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="4">
                        <v-textarea
                          outlined
                          dense
                          v-model="cycleGroupTemplate.notes"
                          label="(optional) Notes"
                          :readonly="!cycleGroupTemplate.editMode"
                          color="primaryAccent"
                        ></v-textarea>
                      </v-col>
                    </v-row>
                    <v-col cols="12">
                      <v-divider />
                    </v-col>
                    <v-select
                      v-model="cycleGroupTemplate.cycleGroupTemplateRegisterTypes"
                      :items="registerTypes"
                      item-text="text"
                      return-object
                      label="(optional) Register Types for this Template"
                      outlined
                      dense
                      multiple
                      chips
                      small-chips
                      :readonly="!cycleGroupTemplate.editMode"
                      deletable-chips
                      color="primaryAccent"
                    ></v-select>
                    <v-col cols="12">
                      <mex-btn v-if="!cycleGroupTemplate.editMode && !cycleGroupTemplate.immutable" :disabled="!nooneElseIsInEditMode" content="Edit" @click="cycleGroupTemplate.editMode = nooneElseIsInEditMode"></mex-btn>
                      <template v-else-if="!cycleGroupTemplate.editMode && cycleGroupTemplate.immutable">
                        <v-row align="center" justify="center">
                          <v-col cols="3">
                            <mex-btn :disabled="cycleGroupTemplate.immutable" content="Edit"></mex-btn>
                          </v-col>
                          <v-col cols="9" class="d-flex justify-center">
                            <v-card color="#a0a0a0" flat outlined>
                              <v-card-text class="d-flex align-center justify-center">
                                <v-icon left>mdi-alert</v-icon>
                                <span>This template is immutable. Add your individual template for customized templates.</span>
                              </v-card-text>
                            </v-card>
                          </v-col>
                        </v-row>
                      </template>
                      <template v-else>
                        <mex-btn :disabled="!validTemplate(cycleGroupTemplate)" content="Save changes" @click="() => saveChanges(cycleGroupTemplateIdx)"></mex-btn>
                        <mex-btn content="Cancel" @click="() => cycleGroupTemplate.cycleGroupTemplateID ? reloadCycleGroupTemplate(cycleGroupTemplateIdx) : deleteTemplate(cycleGroupTemplateIdx)"></mex-btn>
                      </template>
                    </v-col>
                  </mex-sheet>
                </v-col>
              </v-layout>
            </transition-group>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col cols="12">
            <mex-btn :disabled="!nooneElseIsInEditMode" content="Add a template" icon="mdi-plus-box" width="100%" @click="addCycleTypeCounter" />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <mex-dialog
      :show-dialog="showDeletionWarnDialog"
      dialog-title="Confirm Deletion"
      width="30%"
      height="20%"
    >
      <template v-slot:dialog-content>
        <v-row>
          <v-col>
            <v-divider />
          </v-col>
        </v-row>
        <v-row>
          <v-col align-self="center" cols="auto">
            <mex-p :content="deletionWarningText" fontSize="title" fontWeight="bold" />
          </v-col>
        </v-row>
        <v-row class="d-flex justify-space-between">
          <v-col cols="auto">
            <mex-btn content="Confirm Deletion" @click="() => deleteTemplate(deleteCandidateIndex)"/>
          </v-col>
          <v-col cols="auto">
            <mex-btn content="Cancel" @click="showDeletionWarnDialog = false"/>
          </v-col>
        </v-row>
      </template>
    </mex-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import RegisterTypesService from '../../services/registerTypes.service';
import CycleTypesService from '../../services/cycleTypes.service';
import CycleGroupTemplatesService from "../../services/cycleGroupTemplates.service";
import MexBtn from "../MedITEX_Vue_Components/MexComponents/MexButton";
import MexDialog from "../MedITEX_Vue_Components/MexComponents/MexDialog";
// import keyListenerManager from '../../functions/keyListenerManager';

export default {
  components: { MexDialog, MexBtn },
  name: 'RentalLicenseTemplate',
  props: {
    locationID: {
      type: Number,
      default: null,
      description: '',
    },
  },
  data() {
    return {
      // visualization
      editMode: false,
      editModeAndSaveShortCutListener: null,
      boundEditModeAndSaveShortCutListener: null,
      showSaveRequest: false,
      cycleGroupTemplatesLoading: false,
      cycleGroupTemplatesLoadingText: '',
      // data
      registerTypes: [],
      cycleTypeGroups: [],
      cycleTypes: [],
      cycleGroupTemplates: [],
      cycleTypeSelectorTemplate: {
        selectedCycleType: null,

      },
      cycleGroupTemplateTemplate: {
        cycleGroupTemplatesID: null,
        cycleTypeGroups: [],
        cycleGroupTemplateRegisterTypes: [],
        editMode: true,
        cycleTypes: []
      },
      showCycleTypes: false,
      thresholdCategories: [],
      cycleGroupTemplateRules: {
        unique: true
      },
      showDeletionWarnDialog: false,
      deletionWarningText: "Are you sure you want to delete this template and all related database-entries?",
      deleteCandidateIndex: null
    };
  },
  computed: {
    ...mapGetters('sysAuth', ['getUserPermissions']),
    writeRentalLicense() {
      return this.getUserPermissions.includes('write_License Administration_Rental License');
    },
    nooneElseIsInEditMode() {
      return this.cycleGroupTemplates.every(template => template.editMode === false);
    },
  },
  watch: {
    editMode: {
      handler() {
        if (!this.editMode) {
          this.getCycleGroupTemplates();
        }
        this.$emit('editModeChanged', this.editMode);
      },
    },
  },
  methods: {
    adjustCycleTypes(template, cycleType) {
      for (let i = 0; i < template.cycleTypes.length; i++) {
        if (template.cycleTypes[i].value === cycleType) {
          template.cycleTypes[i].disabled = true;
        } else if (template.cycleTypes[i].disabled && (template.cycleTypeGroups.findIndex(ct => ct.selectedCycleType === template.cycleTypes[i].value) === -1)) {
          template.cycleTypes[i].disabled = false;
        }
      }
    },
    deleteTemplate(templateIndex) {
      if (this.cycleGroupTemplates[templateIndex].cycleGroupTemplateID) {
        CycleGroupTemplatesService.deleteCycleGroupTemplate(this.cycleGroupTemplates[templateIndex].cycleGroupTemplateID)
          .then(() => {
            this.removeCycleGroupTemplate(templateIndex);
            this.$toast.success("Successfully deleted group from database");
            this.showDeletionWarnDialog = false;
          })
          .catch((err) => {
            this.$toast.error("Problem while deleting CycleGroupTemplate from database");
          });
      } else {
        this.removeCycleGroupTemplate(templateIndex)
        this.showDeletionWarnDialog = false;
      }
    },
    validTemplate(template) {
      if (!template.name) {
        return false;
      }
      for (let j = 0; j < template.cycleTypeGroups.length; j++) {
        if (template.cycleTypeGroups[j].selectedCycleType === null) {
          return false;
        }
      }
      return true;
    },
    reloadCycleGroupTemplate(templateIndex) {
      CycleGroupTemplatesService.getCycleGroupTemplate(this.cycleGroupTemplates[templateIndex].name)
        .then((response) => {
          this.$set(this.cycleGroupTemplates, templateIndex, this.databaseTemplateToFrontendTemplate(response.data.cycleGroupTemplate));
        })
        .catch((err) => {
          this.$toast.error("Problem while reloading CycleGroupTemplate");
        });
    },
    fillCycleTypeSelector() {
      return RegisterTypesService.getRegisterTypeNames()
        .then((registerTypesResponse) => {
          registerTypesResponse.data
            .filter((x) => x.RegisterTypeID !== 1)
            .forEach((registerType) => {
              const entry = {
                value: registerType.RegisterTypeID,
                text: registerType.acronym,
                description: '',
              };
              registerType.CycleTypes.forEach((type) => {
                entry.description += `${type.name} | `;
              });
              this.registerTypes.push(entry);
            });
          CycleTypesService.getCycleTypeNames().then((cycleTypesResponse) => {
            cycleTypesResponse.data.forEach((cycleType) => {
              this.cycleTypes.push({
                value: cycleType.CycleTypeID,
                text: cycleType.name,
              });
            });
          });
        })
        .catch((err) => {
          this.$toast.error(err.message);
        });
    },
    addCycleTypeSelector(cycleGroupTemplate) {
      const newCycleTypeSelector = JSON.parse(JSON.stringify({ ...this.cycleTypeSelectorTemplate }));
      cycleGroupTemplate.cycleTypeGroups.push(JSON.parse(JSON.stringify({ ...newCycleTypeSelector })));
    },
    calculateTemplateCycleTypes(template) {
      // cycleTypes which keep track of if the individual cycle type can be selected (when it has not been selected yet for that template) or not
      return this.cycleTypes.map((cycleType) => ({
        ...cycleType,
        disabled: template.CycleTypeGroups.findIndex((cycleTypeGroup) => cycleTypeGroup.CycleType.CycleTypeID === cycleType.value) !== -1
      }));
    },
    addCycleTypeCounter() {
      const newCycleTypeGroup = JSON.parse(JSON.stringify({ ...this.cycleGroupTemplateTemplate }));
      newCycleTypeGroup.cycleTypeGroups.push(JSON.parse(JSON.stringify({ ...this.cycleTypeSelectorTemplate })));
      newCycleTypeGroup.locationID = this.locationID;
      this.cycleGroupTemplates.push(JSON.parse(JSON.stringify({ ...newCycleTypeGroup })));
      const newestEntry = this.cycleGroupTemplates[this.cycleGroupTemplates.length - 1];
      this.cycleGroupTemplates[this.cycleGroupTemplates.length - 1].cycleTypes = this.cycleTypes.map((cycleType) => ({
        ...cycleType,
        disabled: newestEntry.cycleTypeGroups.findIndex((cycleTypeGroup) => cycleTypeGroup.selectedCycleType === cycleType.value) !== -1
      }));
    },
    removeCycleTypeSelector(cycleGroupTemplateIdx, cycleTypeSelectorIdx) {
      const cycleTypeIndex = this.cycleTypes.findIndex((type) => type.value === this.cycleGroupTemplates[cycleGroupTemplateIdx].cycleTypeGroups[cycleTypeSelectorIdx].selectedCycleType);
      if (cycleTypeIndex !== -1) {
        this.cycleGroupTemplates[cycleGroupTemplateIdx].cycleTypes[cycleTypeIndex].disabled = false;
      }
      this.cycleGroupTemplates[cycleGroupTemplateIdx].cycleTypeGroups.length > 1
        ? this.cycleGroupTemplates[cycleGroupTemplateIdx].cycleTypeGroups.splice(cycleTypeSelectorIdx, 1)
        : this.cycleGroupTemplates[cycleGroupTemplateIdx].cycleTypeGroups = [JSON.parse(JSON.stringify({ ...this.cycleTypeSelectorTemplate }))];
    },
    removeCycleGroupTemplate(cycleGroupTemplateIdx) {
      this.cycleGroupTemplates.splice(cycleGroupTemplateIdx, 1);
    },
    saveChanges(templateIndex) {
      this.cycleGroupTemplates[templateIndex].editMode = false;
      CycleGroupTemplatesService.createCycleGroupTemplate(this.cycleGroupTemplates[templateIndex])
        .then(() => {
          this.$toast.success('Updated template');
          this.getCycleGroupTemplates();
        })
        .catch((err) => {
          this.$toast.error('Problem while updating license-settings');
        });
    },
    closeEditMode() {
      this.editMode = false;
    },
    async getCycleGroupTemplates() {
      this.cycleGroupTemplatesLoadingText = 'Loading location cycle types and threshold-categories';
      this.cycleGroupTemplatesLoading = true;
      this.editMode = false;
      this.cycleGroupTemplates = [];
      CycleGroupTemplatesService.getCycleGroupTemplates()
        .then((cycleGroupTemplates) => {
          this.cycleGroupTemplatesLoading = false;
          this.cycleGroupTemplates = cycleGroupTemplates.data.cycleGroupTemplates
            .map(template => this.databaseTemplateToFrontendTemplate(template));
        })
        .catch((err) => {
          this.$toast.error('Problem while loading cycle-group-templates');
        });
    },
    databaseTemplateToFrontendTemplate(template) {
      const templateCycleGroupTemplateRegisterTypes = template.CycleGroupTemplateRegisterTypes
        ? template.CycleGroupTemplateRegisterTypes.map((cgtrt) => ({
          description: cgtrt.RegisterType.description,
          text: cgtrt.RegisterType.acronym,
          value: cgtrt.RegisterType.RegisterTypeID
        }))
        : [];
      const templateCycleTypeGroups = template.CycleTypeGroups.map((cycleTypeGroup) => ({
          selectedCycleType: cycleTypeGroup.CycleTypeID
      }));
      return {
        cycleGroupTemplateID: template.CycleGroupTemplateID,
        name: template.name,
        notes: template.notes,
        immutable: template.immutable,
        cycleGroupTemplateRegisterTypes: templateCycleGroupTemplateRegisterTypes,
        cycleTypeGroups: templateCycleTypeGroups,
        cycleTypes: this.calculateTemplateCycleTypes(template),
        editMode: false
      }
    }
  },
  async created() {
    this.fillCycleTypeSelector().then(() => this.getCycleGroupTemplates());
  },
};
</script>

<style>
.rentalLicenseCounterSheet {
  box-shadow: inset 0 0 5px var(--v-primary-darken2) !important;
  max-height: 50vh;
  overflow-x: hidden;
  overflow-y: scroll;
  padding: 10px;
}
</style>
